module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/node_modules/gatsby-theme-banshorian/src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Muhammad Umair Ghufran developer portfolio | @rankgrain","short_name":"Merry","description":"This cool App contains information about my work experience as a software developer.","lang":"en","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"/opt/build/repo/node_modules/gatsby-theme-banshorian/src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/node_modules/gatsby-theme-banshorian/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["*"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"send_page_view":true,"cookie_name":"http://umairghufran.com"},"pluginConfig":{"head":true,"respectDNT":true}},
    }]
